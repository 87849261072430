import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

const makeAPayment = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout language={pageContext.language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />

        <div className="columns top-section color-back">
          <div className="column is-4"></div>
          <div className="column">
            <h1 style={{ marginTop: 0, marginBottom: 0 }}>{post.heading}</h1>
          </div>
          <div className="column is-4"></div>
        </div>

        <div className="columns" style={{ margin: "50px 0" }}>
          <div className="column is-4"></div>
          <div className="column">
            <p>{post.payOnline.blurb}</p>
            <ButtonGroupMap isCentered buttons={post.payOnline.buttons} />
          </div>
          <div className="column is-4"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query MyQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        metaDescription
        metaTitle
        redirects {
          approved
          declined
        }
        payOnline {
          heading
          blurb
          buttons {
            button {
              href
              appearance
              destination
              buttonText
            }
          }
        }
      }
    }
    site: allSite {
      nodes {
        siteMetadata {
          siteUrl
          fullPracticeName
        }
      }
    }
  }
`

export default makeAPayment
